<template>
  <TresGroup>
    <primitive
      :key="`tres-edit-${props.meshData._uid}`"
      :object="instancedMesh"
    />
    <primitive
      :object="boundingBoxMesh"
      @pointer-leave="onPointerLeave"
      @pointer-move="onPointerMove"
      @pointer-down="onPointerDown"
      @pointer-enter="onPointerEnter"
    />
  </TresGroup>
</template>

<script setup>
import {
    BoxGeometry,
    MeshBasicMaterial,
    InstancedMesh,
    Matrix4,
    Object3D,
    Raycaster,
    Vector3,
    Mesh,
} from 'three';

const props = defineProps({
    meshData: {
        type: Object,
        default: null,
    },
    lockedPins: {
        type: Array,
        default: () => [],
    },
    userData: {
        type: Object,
        default: () => {},
    },
    isArt: {
        type: Boolean,
        default: false,
    },
});

/*
    Global variables
*/
let instancedMesh = null;
let boundingBoxMesh = null;
let geometry = null;
let material = null;
const dummy = new Object3D();
const raycaster = new Raycaster();
const { camera } = useTresContext();

/*
    Check if a pin is locked
*/
const isLocked = (group, sector) => props.lockedPins.some(
    (pin) => pin.group === group && pin.sector === sector,
);

/*
    Set the cubes and create the bounding box
*/
const instanceUserData = {};

const setEditCubes = (item) => {
    let index = 0;
    const {
        groupSizes, sectorSizes, groups, min, boundingBox,
    } = item;

    // Define the gap size
    const gapSize = 0.1;

    // Calculate box size dynamically, including gap
    const boxWidth = sectorSizes.x - gapSize;
    const boxDepth = sectorSizes.z - gapSize;
    const boxHeight = 0.05; // Assuming a fixed height for the boxes

    // Calculate the total number of cubes needed
    const sectors = {
        x: Math.ceil(groupSizes.x / sectorSizes.x),
        z: Math.ceil(groupSizes.z / sectorSizes.z),
    };
    const totalCubes = (groups.x * groups.z * sectors.x * sectors.z) - props.lockedPins.length;

    // Create geometry and material with dynamic size
    geometry = new BoxGeometry(boxWidth, boxHeight, boxDepth);
    material = new MeshBasicMaterial(
        {
            color: 0xffffff,
            transparent: true,
            opacity: 0.65,
        },
    );

    // Create a new InstancedMesh with the correct count
    instancedMesh = new InstancedMesh(geometry, material, totalCubes);
    instancedMesh.name = Math.random();

    for (let groupIndex = 0; groupIndex < groups.x * groups.z; groupIndex += 1) {
        const length = sectors.x * sectors.z;
        const groupStart = {
            x: min.x + (groupIndex % groups.x) * groupSizes.x,
            z: min.z + Math.floor(groupIndex / groups.x) * groupSizes.z,
        };

        for (let innerIndex = 0; innerIndex < length; innerIndex += 1) {
            // Only render cubes that are not locked
            if (!isLocked(groupIndex, innerIndex)) {
                const position = {
                    x: (groupStart.x + (
                        innerIndex % sectors.x
                    ) * (boxWidth + gapSize) + boxWidth / 2) + 0.05,
                    z: groupStart.z + (Math.floor(
                        innerIndex / sectors.x,
                    ) * (boxDepth + gapSize) + boxDepth / 2) + 0.05,
                    y: min.y + 0.35,
                };

                dummy.position.set(position.x, position.y, position.z);
                dummy.updateMatrix();
                instancedMesh.setMatrixAt(index, dummy.matrix);

                instanceUserData[index] = {
                    group: groupIndex,
                    sector: innerIndex,
                };

                index += 1;
            }
        }
    }

    instancedMesh.instanceMatrix.needsUpdate = true;

    // Use the boundingBox from meshData
    const boundingBoxMin = new Vector3(boundingBox.min.x, boundingBox.min.y, boundingBox.min.z);
    const boundingBoxMax = new Vector3(boundingBox.max.x, boundingBox.max.y, boundingBox.max.z);

    // Calculate bounding box dimensions and center
    const boundingBoxSize = new Vector3().subVectors(boundingBoxMax, boundingBoxMin);
    const boundingBoxCenter = new Vector3().addVectors(
        boundingBoxMin,
        boundingBoxMax,
    ).multiplyScalar(0.5);

    // Create a bounding box mesh
    const boundingBoxGeometry = new BoxGeometry(
        boundingBoxSize.x,
        0.01,
        boundingBoxSize.z,
    );
    const boundingBoxMaterial = new MeshBasicMaterial({
        color: 0x000000,
        transparent: true,
        opacity: 0,
    });
    boundingBoxMesh = new Mesh(boundingBoxGeometry, boundingBoxMaterial);
    boundingBoxMesh.position.copy({
        x: boundingBoxCenter.x,
        y: boundingBoxCenter.y + 0.3,
        z: boundingBoxCenter.z,
    });
};

setEditCubes(props.meshData);

/*
    Enter edit mode
*/
const { gsap } = useGsap();
const isRunning = shallowRef(false);
const animateMesh = (isEditMode) => {
    if (isRunning.value) return;

    isRunning.value = true;
    if (instancedMesh) {
        gsap.to(material, {
            opacity: isEditMode ? 0.65 : 0,
            duration: 0.3,
            ease: 'power2.out',
        });

        gsap.to(instancedMesh.position, {
            y: isEditMode ? '+=0.15' : '-=0.15',
            duration: 0.4,
            ease: isEditMode ? 'back.out(8.7)' : 'power2.out',
            onComplete: () => {
                isRunning.value = false;
            },
        });
    }
};

/*
    Listen for edit mode changes
*/
const { onBeforeRender } = useLoop();
let storedValue = window.sessionStorage.getItem('edit-mode-enabled');

onBeforeRender(() => {
    const enabled = window.sessionStorage.getItem('edit-mode-enabled');
    const type = window.sessionStorage.getItem('edit-mode-type');

    if (props.isArt && type === 'art') {
        if (enabled !== storedValue) {
            animateMesh(enabled === 'true');
            storedValue = enabled;
        }
    }

    if (!props.isArt && type === 'selfie') {
        if (enabled !== storedValue) {
            animateMesh(enabled === 'true');
            storedValue = enabled;
        }
    }
}, 0);

/*
    Hover effects
*/
const currentInstanceId = shallowRef(null);
const oldInstanceId = shallowRef(null);
const instanceAnimating = {}; // Object to keep track of animating instances

const updateInstanceMatrix = (instanceId, yOffset) => {
    if (instanceAnimating[instanceId]) {
        instanceAnimating[instanceId].push(yOffset);
        return; // Exit if this instance is already animating and queue the new animation
    }

    const matrix = new Matrix4();
    instancedMesh.getMatrixAt(instanceId, matrix);
    const position = new Vector3();
    position.setFromMatrixPosition(matrix);

    instanceAnimating[instanceId] = []; // Initialize queue for this instance

    const animate = (yO) => {
        gsap.to(position, {
            y: position.y + yO,
            duration: 0.2,
            ease: 'power2.out',
            onUpdate: () => {
                matrix.setPosition(position);
                instancedMesh.setMatrixAt(instanceId, matrix);
                instancedMesh.instanceMatrix.needsUpdate = true;
            },
            onComplete: () => {
                if (instanceAnimating[instanceId].length > 0) {
                    // Start next animation in the queue
                    animate(instanceAnimating[instanceId].shift());
                } else {
                    delete instanceAnimating[instanceId]; // No more animations, clear the flag
                }
            },
        });
    };

    animate(yOffset);
};

const onPointerMove = (event) => {
    if (storedValue === 'true') {
        const { point } = event;
        const vector = point.clone();

        raycaster.set(
            camera.value.position,
            vector.sub(camera.value.position).normalize(),
        );

        const intersects = raycaster.intersectObject(instancedMesh);

        if (intersects.length > 0) {
            oldInstanceId.value = currentInstanceId.value;
            const intersectsObj = intersects[0];
            if (!intersectsObj || currentInstanceId.value === intersectsObj.instanceId) return;

            const { instanceId } = intersectsObj;
            currentInstanceId.value = instanceId;

            updateInstanceMatrix(instanceId, 0.3);

            if (oldInstanceId.value !== null) {
                updateInstanceMatrix(oldInstanceId.value, -0.3);
            }
        }
    }
};

const emit = defineEmits([
    'on-select-position',
]);

const onPointerLeave = () => {
    setCursorVisibility(false);

    if (storedValue === 'true' && currentInstanceId.value !== null) {
        updateInstanceMatrix(currentInstanceId.value, -0.3, 1);
        oldInstanceId.value = currentInstanceId.value;
        currentInstanceId.value = null;
    }
};

/*
    Click event
*/
const onPointerDown = async (event) => {
    if (storedValue === 'true' && currentInstanceId.value !== null) {
        const { point } = event;
        const vector = point.clone();

        raycaster.set(
            camera.value.position,
            vector.sub(camera.value.position).normalize(),
        );

        const intersects = raycaster.intersectObject(instancedMesh);

        if (intersects.length > 0) {
            const intersectsObj = intersects[0];
            const { instanceId } = intersectsObj;

            if (instanceId !== undefined) {
                const userData = instanceUserData[instanceId];
                emit('on-select-position', userData);
            }
        }
    }
};

const onPointerEnter = () => {
    if (storedValue === 'true') {
        setCursorVisibility(true);
    }
};
</script>
