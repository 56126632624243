<template>
  <div
    :class="[
      `is-behaviour-${behaviour}`,
      `is-scaling-direction-${scalingDirection}`,
      { 'is-on-grid': isOnGrid },
      { 'has-border-radius': hasBorderRadius },
    ]"
    class="atom-image__wrapper"
  >
    <div class="atom-image__background">
      <NuxtImg
        v-if="!isSvg && mergedProps.src"
        decoding="auto"
        class="atom-image"
        v-bind="{ ...mergedProps }"
        :densities="isSvg ? null : 'x1 x1.5 x2'"
        :sizes="isSvg ? null : sizes"
      />

      <img
        v-else-if="mergedProps.src"
        :src="mergedProps.src"
        :alt="mergedProps.alt"
      />
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
    /* Image Data */
    image: {
        type: Object,
        required: true,
        validator: (value) => typeof value === 'object',
    },

    provider: {
        type: String,
        default: 'storyblok',
    },

    /* Options */
    width: {
        type: [String, Boolean, Number],
        default: false,
    },

    height: {
        type: [String, Boolean, Number],
        default: false,
    },

    aspectRatio: {
        type: [String, Boolean],
        default: false,
    },

    lazy: {
        type: Boolean,
        default: true,
    },

    lazyDelay: {
        type: Number,
        default: 0,
    },

    background: {
        type: [String, Boolean],
        default: false,
    },

    preload: {
        type: Boolean,
        default: false,
    },

    /* Storyblok only */
    format: {
        type: String,
        default: 'webp',
        validator: (value) => ['webp', 'jpg', 'png'].includes(value),
    },

    smartCrop: {
        type: Boolean,
        default: false,
        validator: (value) => typeof (value) === 'boolean',
    },

    focus: {
        type: [String, Boolean],
        default: false,
    },

    sizes: {
        type: [String, Boolean],
        default: null,
    },

    /*
          Layout
    */
    isOnGrid: {
        type: Boolean,
        default: false,
    },

    behaviour: {
        type: String,
        default: 'default',
        validator: (value) => ['default', 'cover', 'contain'].includes(value),
    },

    scalingDirection: {
        type: String,
        default: 'width',
        validator: (value) => ['width', 'height'].includes(value),
    },

    hasBorderRadius: {
        type: Boolean,
        default: false,
    },
});

/* Lazy loading background */
const backgroundColor = computed(() => {
    if (props.background) {
        return props.background;
    }

    return 'transparent';
});

/*
      Build props for Nuxt Image component
  */
const generalProps = computed(() => ({
    loading: props.lazy ? 'lazy' : 'eager',
    preload: props.preload,
    sizes: props.sizes ? props.sizes : null,
}));

const buildStoryblokProps = () => {
    const modifiers = {
        filters: {},
    };

    if (props.smartCrop) {
        modifiers.smart = true;
    }

    if (props.focus && !props.smartCrop) {
        modifiers.filters.focal = props.focus;
    }

    const dimensions = {
        width: props.width ? props.width : null,
        height: props.height ? props.height : null,
    };

    if (dimensions.width && !dimensions.height) {
        const splittedUrl = props.image?.filename.split('/')[5].split('x');
        const originalWidth = parseInt(splittedUrl[0], 10);
        const originalHeight = parseInt(splittedUrl[1], 10);

        dimensions.width = originalWidth < dimensions.width ? originalWidth : dimensions.width;
        dimensions.height = getImageHeight(originalWidth, dimensions.width, originalHeight);
    }

    if (props.aspectRatio) {
        const width = parseInt(props.width, 10);
        const [ratioX, ratioY] = props.aspectRatio.split(':');
        dimensions.width = `${width}px`;
        dimensions.height = `${(width / ratioX) * ratioY}px`;
    }

    return {
        provider: 'storyblok',
        src: props.image?.filename,
        alt: props.image?.alt,
        format: props.format,
        modifiers,
        ...dimensions,
    };
};

const buildIpxProps = () => ({
    provider: null,
    src: props.image?.src,
    alt: props.image?.alt,
    width: props.width ? props.width : null,
    height: props.height ? props.height : null,
});

const imageProps = computed(() => {
    switch (props.provider) {
    case 'storyblok':
        return buildStoryblokProps();
    case 'ipx':
    default:
        return buildIpxProps();
    }
});

const mergedProps = computed(() => ({
    ...generalProps.value,
    ...imageProps.value,
}));

/*
      Check for svg
  */
const isSvg = computed(() => mergedProps.value?.src?.includes('.svg'));
</script>

<style lang="scss" scoped>
.atom-image__wrapper {
    &.is-on-grid {
        @include wrapper-layout;
        @include grid-layout();
        @include fluid-simple('margin-bottom', 30px, 60px);
    }

    &.is-behaviour-cover,
    &.is-behaviour-contain{
        position: absolute;
        overflow: hidden;
        width: 100%;
        height: 100%;
    }
}
.atom-image__background {
    display: flex;
    width: 100%;
    align-self: flex-start;
    background: v-bind(backgroundColor);

    .has-border-radius & {
        overflow: hidden;
        border-radius: 12px;
    }

    .is-scaling-direction-width & {
        width: 100%;
        height: auto;
    }

    .is-scaling-direction-height & {
        width: auto;
        height: 100%;
    }

    .is-behaviour-cover &,
    .is-behaviour-contain &{
        width: 100%;
        height: 100%;
    }

    img {
        width: 100%;

        .is-scaling-direction-width & {
            width: 100%;
            height: auto;
        }

        .is-scaling-direction-height & {
            width: auto;
            height: 100%;
        }

        .is-behaviour-cover &,
        .is-behaviour-contain & {
            width: 100%;
            height: 100%;
        }

        .is-behaviour-cover & {
            object-fit: cover;
        }

        .is-behaviour-contain & {
            object-fit: contain;
        }
    }

    .is-on-grid & {
        @include default-content-columns;
    }
}
.atom-image {
    .is-scaling-direction-width & {
        width: 100%;
        height: auto;
    }

    .is-scaling-direction-height & {
        width: auto;
        height: 100%;
    }
}
</style>
