<template>
  <div
    v-if="positionY && positionX"
    class="atom-pin"
    :class="{
      'is-hidden': isHidden || isInitial,
      'has-text-content': text,
      'has-transition-delay': hasTransitionDelay,
    }"
    :style="{
      top: `${positionY}px`,
      left: `${positionX}px`,
      zIndex,
    }"
  >
    <div class="atom-pin__line-wrapper">
      <div class="atom-pin__line" />
      <div class="atom-pin__line" />
      <div class="atom-pin__line" />
      <div class="atom-pin__line" />
      <div class="atom-pin__line" />

      <div class="atom-pin__shine" />

      <div
        class="atom-pin__pin"
        type="button"
        @click="onClick"
        @keypress="onClick"
      >
        <span class="atom-pin__text">{{ text }}</span>

        <AtomImage
          v-if="image?.src"
          :image="image"
          :provider="null"
          :width="200"
          class="atom-pin__image"
          :height="200"
          behaviour="cover"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
    positionX: {
        type: Number,
        default: null,
    },

    positionY: {
        type: Number,
        default: null,
    },

    zIndex: {
        type: Number,
        default: 1,
    },

    isHidden: {
        type: Boolean,
        default: false,
    },

    borderRadius: {
        type: String,
        default: 'var(--b-radius--full)',
    },

    hasTransitionDelay: {
        type: Boolean,
        default: false,
    },

    scale: {
        type: Number,
        default: 1,
    },

    /*
        Content
    */
    image: {
        type: Object,
        default: null,
    },

    text: {
        type: [String, Number],
        default: null,
    },
});

const {
    borderRadius,
    scale,
} = toRefs(props);

const emit = defineEmits(['on-click', 'on-zoom']);
const onClick = () => {
    if (props.text) {
        emit('on-zoom');
        useStorybookAction('on-zoom');
        return;
    }

    emit('on-click');
    useStorybookAction('on-click');
};

const isInitial = ref(true);
onMounted(() => {
    window.setTimeout(() => {
        isInitial.value = false;
    }, 200);
});
</script>

<style lang="scss" scoped>
.atom-pin {
    --transition-duration: 0.4s;
    --transition-delay: 0s;
    position: absolute;
    width: 3px;
    height: 3px;
    border-radius: var(--b-radius--full);
    background: var(--c-white);
    font-family: var(--f-family--primary);
    font-size: 10px;
    font-weight: 600;
    line-height: 10px;
    pointer-events: none;
    text-align: center;
    transform: scale(v-bind(scale));
    transition: width var(--transition-duration) var(--ease-out--back), height var(--transition-duration) var(--ease-out--back);
    transition-delay:var(--transition-delay);

    &.has-transition-delay {
        --transition-delay: 0.6s;
    }

    &.is-hidden {
        width: 0;
        height: 0;
        transition-delay: 0s;
    }
}

.atom-pin__line-wrapper {
    position: absolute;
    right: 0;
    bottom: 1.5px;
    left: 0;
    display: flex;
    width: 1px;
    height: 16px;
    flex-direction: column;
    margin: auto;
    row-gap: 1px;
    transition: height var(--transition-duration) var(--ease-out--back);
    transition-delay:var(--transition-delay);

    .has-text-content & {
        height: 20px;
    }

    .is-hidden & {
        height: 0;
        transition-delay: 0s;

        .has-text-content & {
            height: 0;
        }
    }

}

.atom-pin__line {
    flex-basis: 100%;
    border-radius: var(--b-radius--full);
    background: var(--c-white);
}

.atom-pin__pin {
    position: absolute;
    top: 0;
    left: 50%;
    display: flex;
    overflow: hidden;
    width: 19px;
    height: 19px;
    align-items: center;
    justify-content: center;
    border: 4px solid #DE8ED942;
    border-radius: v-bind(borderRadius);
    margin: auto;
    background-color: var(--c-white);
    cursor: pointer;
    pointer-events: all;
    transform: translateX(-50%) translateY(-100%);
    transition: width var(--transition-duration) var(--ease-out--back), height var(--transition-duration) var(--ease-out--back), border-radius var(--transition-duration) var(--ease-out--back),border-width var(--transition-duration) var(--ease-out--back);
    transition-delay:var(--transition-delay);

    .is-hidden & {
        width: 0 !important;
        height: 0 !important;
        border-width: 0 !important;
        transition-delay: 0s;
    }

    .has-text-content & {
        width: 24px;
        height: 24px;
    }

    @include only-hover {
        &:hover {
            width: 53px;
            height: 53px;

            .has-text-content & {
                width: 33px;
                height: 33px;

            }

            .atom-pin__image {
                opacity: 1;
                pointer-events: all;
            }
        }
    }
}

.atom-pin__image {
    border: 2px solid var(--c-white);
    border-radius: v-bind(borderRadius);
    opacity: 0;
    pointer-events: none;
    transition: opacity var(--transition-duration) var(--ease-out--quart), border-radius var(--transition-duration) var(--ease-out--quart);

}

.atom-pin__text {
    opacity: 1;
    pointer-events: none;
    transition: opacity var(--transition-duration) var(--ease-out--quart);
    transition-delay:var(--transition-delay);

    .is-hidden & {
        opacity: 0;
        transition-delay: 0s;
    }
}
</style>
