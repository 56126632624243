<!-- eslint-disable vue/attribute-hyphenation -->
<template>
  <div
    class="block-tres-purpletable"
    :class="{ 'no-pointers': route.path !== ('/' || '/home') && !route.path.includes('settings') }"
  >
    <ClientOnly>
      <TresCanvas
        clear-color="#E2C8E0"
        :antialias="false"
        window-size
        :alpha="true"
        :logarithmic-depth-buffer="true"
      >
        <TresPerspectiveCamera
          ref="cameraRef"
          :near="0.1"
          :far="1000"
          :look-at="[0, 10, 0]"
          :position="[
            initialCameraPosition.x,
            initialCameraPosition.y,
            initialCameraPosition.z,
          ]"
        />

        <TresFog :args="['#E2C8E0', 40, 80]" />

        <TresMesh
          :rotation-x="-Math.PI / 2"
          :position="[0, 0.05, 0]"
          :polygonOffsetFactor="0.5"
          :polygonOffsetUnits="2"
          :renderOrder="1"
        >
          <TresMeshBasicMaterial
            :alpha-map="alphaMap"
            color="#E2C8E0"
            :depthWrite="false"
            :depthTest="true"
            transparent
          />

          <TresPlaneGeometry
            :args="[160, 160]"
          />
        </TresMesh>

        <OrbitControls
          ref="controlsRef"
          makeDefault
          :enableZoom="zoomEnabled"
          :min-polar-angle="degreesToRadians(0)"
          :max-polar-angle="degreesToRadians(89)"
          :min-distance="18"
          :max-distance="100"
        />

        <Suspense>
          <BlockTresModelsBaked
            v-if="pinsResult?.approved"
            v-bind="pinsResult.approved"
            @on-update-art-pins="setPinsPositions($event, 'artPins')"
            @on-update-group-pins="setPinsPositions($event, 'groupPins')"
            @on-loaded-model="loadedStates.model = true"
            @on-loaded-texture="loadedStates.texture = true"
            @on-set-mesh-data="setMeshData"
          />
        </Suspense>

        <BlockTresEdit
          v-if="meshData?.tisch"
          :mesh-data="meshData.tisch"
          :is-art="true"
          :locked-pins="pinsResult?.all?.tableContributions"
          @on-select-position="onSelectedConfirmation($event, 'table', 'art')"
        />

        <BlockTresEdit
          v-if="meshData?.bank001"
          :mesh-data="meshData.bank001"
          :locked-pins="pinsResult?.all?.benchOneContributions"
          :is-art="false"
          @on-select-position="onSelectedConfirmation($event, 'bench_one', 'selfie')"
        />

        <BlockTresEdit
          v-if="meshData?.bank002"
          :mesh-data="meshData.bank002"
          :locked-pins="pinsResult?.all?.benchTwoContributions"
          :is-art="false"
          @on-select-position="onSelectedConfirmation($event, 'bench_two', 'selfie')"
        />

        <BlockTresEdit
          v-if="meshData?.bank003"
          :mesh-data="meshData.bank003"
          :locked-pins="pinsResult?.all?.benchThreeContributions"
          :is-art="false"
          @on-select-position="onSelectedConfirmation($event, 'bench_three', 'selfie')"
        />

        <BlockTresEdit
          v-if="meshData?.bank"
          :mesh-data="meshData.bank"
          :locked-pins="pinsResult?.all?.benchFourContributions"
          :is-art="false"
          @on-select-position="onSelectedConfirmation($event, 'bench_four', 'selfie')"
        />
      </TresCanvas>
    </ClientOnly>

    <AtomPin
      v-for="(pin, index) of artPinsPositions"
      :key="`art-pin-${index}`"
      v-bind="pin"
      :is-hidden="pin.isHidden || hidePins"
      :image="pin.data.image"
      :has-transition-delay="hidePins"
      @on-click="onClick($event, pin.data)"
    />

    <AtomPin
      v-for="(pin, index) of groupPinsPositions"
      :key="`group-pin-${index}`"
      v-bind="pin"
      :is-hidden="pin.isHidden || hidePins"
      :text="pin.data.amountOfPins"
      :has-transition-delay="hidePins"
      @on-zoom="onZoom($event, pin.position)"
    />

    <Transition name="fade-in">
      <div v-if="!hidePins" class="block-tres-purpletable__buttons">
        <AtomButton
          class="block-tres-purpletable__button"
          backgroundColor="var(--c-secondary)"
          textColor="var(--c-black)"
          effectColor="var(--c-primary)"
          :text="uploadArtButtonText"
          @click="onEnterEditmode('art')"
        />

        <AtomButton
          class="block-tres-purpletable__button"
          :text="uploadSelfieButtonText"
          backgroundColor="var(--c-secondary)"
          textColor="var(--c-black)"
          effectColor="var(--c-primary)"
          @click="onEnterEditmode('selfie')"
        />
      </div>
    </Transition>

    <BlockOverlayConfirmation
      ref="confirmationRef"
      :is-open="confirmationOpen"
      :confirm-text="getStoryblokTranslation('general', 'confirmation_confirm_text')"
    >
      <AtomTextHeading
        v-if="overlayTitle"
        :text="overlayTitle"
        font-size="h3"
        class="block-overlay-confirmation__title"
      />

      <AtomTextRichtext
        :html="selectedPosition?.type === 'art' ? overlayText : overlaySelfieText"
        class="block-overlay-confirmation__text"
      />
    </BlockOverlayConfirmation>

    <BlockOverlayConfirmation
      ref="selectedConfirmationRef"
      :is-open="selectedConfirmationOpen"
      :confirm-text="getStoryblokTranslation('general', 'selected_confirm_text')"
      :reject-text="getStoryblokTranslation('general', 'selected_reject_text')"
    >
      <AtomTextHeading
        v-if="overlayTitle"
        :text="selectedOverlayTitle"
        font-size="h3"
        class="block-overlay-confirmation__title"
      />

      <AtomTextRichtext
        v-if="overlayText"
        :html="selectedOverlayText"
        class="block-overlay-confirmation__text"
      />
    </BlockOverlayConfirmation>
  </div>
</template>

<script setup>
import {
    Vector3,
    TextureLoader,
} from 'three';

const props = defineProps({
    pins: {
        type: Object,
        default: () => ({}),
    },

    // All pins including not approved pins
    allPins: {
        type: Object,
        default: () => {},
    },

    enterAnimation: {
        type: Boolean,
        default: false,
    },

    uploadArtButtonText: {
        type: String,
        default: '',
    },

    uploadSelfieButtonText: {
        type: String,
        default: '',
    },

    overlayText: {
        type: Object,
        default: null,
    },

    overlaySelfieText: {
        type: Object,
        default: null,
    },

    overlayTitle: {
        type: String,
        default: '',
    },

    selectedOverlayText: {
        type: Object,
        default: null,
    },

    selectedOverlayTitle: {
        type: String,
        default: '',
    },

    uploadArtLink: {
        type: Object,
        default: () => {},
    },

    uploadSelfieLink: {
        type: Object,
        default: () => {},
    },
});

const route = useRoute();
const selectedPosition = useCookie('selected-position');

/*
    Zoon
*/
const zoomEnabled = ref(route.path === '/' || route.path === '/home');

watch(() => route.path, async (newValue) => {
    const isEnabled = newValue === '/' || newValue === '/home';

    if (!isEnabled) {
        await sleep(1000);
    }

    zoomEnabled.value = isEnabled;
});

/*
    Get pins from api
*/
const { find } = useStrapi();

const { data: pinsResult } = useLazyAsyncData(
    'submissions',
    () => find('submissions', {
        populate: 'image',
        pagination: {
            pageSize: 100,
        },
    }),
    {
        transform: (res) => {
            const entries = res.data.map((entry) => {
                const image = entry?.attributes?.image?.data?.attributes;
                const { attributes } = entry;

                const mapped = {
                    group: attributes.group,
                    sector: attributes.sector,
                    link: attributes.slug,
                    _uid: `submission${entry.id}`,
                    assigned_to: attributes.assigned_to,
                    isApproved: attributes.is_approved,
                    image: {
                        src: image?.url,
                        alt: image?.alt,
                    },
                };

                return mapped;
            });

            const grouped = groupArrayByProperty(entries, 'assigned_to');

            const result = {
                approved: {
                    tableContributions: isArrayNotEmpty(grouped.table)
                        ? grouped.table.filter((pin) => pin.isApproved) : [],
                    benchOneContributions: isArrayNotEmpty(grouped.bench_one)
                        ? grouped.bench_one.filter((pin) => pin.isApproved) : [],
                    benchTwoContributions: isArrayNotEmpty(grouped.bench_two)
                        ? grouped.bench_two.filter((pin) => pin.isApproved) : [],
                    benchThreeContributions: isArrayNotEmpty(grouped.bench_three)
                        ? grouped.bench_three.filter((pin) => pin.isApproved) : [],
                    benchFourContributions: isArrayNotEmpty(grouped.bench_four)
                        ? grouped.bench_four.filter((pin) => pin.isApproved) : [],
                },
                all: {
                    tableContributions: grouped.table || [],
                    benchOneContributions: grouped.bench_one || [],
                    benchTwoContributions: grouped.bench_two || [],
                    benchThreeContributions: grouped.bench_three || [],
                    benchFourContributions: grouped.bench_four || [],
                },
            };

            return result;
        },
        server: false,
    },
);

/*
    Edit Mode
*/
const isEditModeEnabled = useCookie('edit-mode-enabled');
const editModeType = useCookie('edit-mode-type');
isEditModeEnabled.value = false;
editModeType.value = 'art';
const isInitial = shallowRef(true);

const toggleEditMode = async (type, forceClose = false) => {
    if (forceClose) {
        isEditModeEnabled.value = false;
        window.sessionStorage.setItem('edit-mode-enabled', isEditModeEnabled.value);
        return;
    }

    if (type === editModeType.value) {
        isEditModeEnabled.value = !isEditModeEnabled.value;
    } else {
        isEditModeEnabled.value = false;
        window.sessionStorage.setItem('edit-mode-enabled', isEditModeEnabled.value);

        if (!isInitial.value) {
            await sleep(250);
        }

        editModeType.value = type;
        isEditModeEnabled.value = true;
    }

    isInitial.value = false;
    window.sessionStorage.setItem('edit-mode-enabled', isEditModeEnabled.value);
    window.sessionStorage.setItem('edit-mode-type', type);
};

onMounted(() => {
    window.sessionStorage.setItem('edit-mode-enabled', isEditModeEnabled.value);
    window.sessionStorage.setItem('edit-mode-type', editModeType.value);
});

const confirmationOpen = ref(false);
const confirmationRef = ref(null);

const onEnterEditmode = async (type) => {
    if (!selectedPosition.value) {
        selectedPosition.value = {};
    }

    selectedPosition.value.type = type;
    if (!isGreaterThanTablet.value) {
        const redirectLink = type === 'art' ? props.uploadArtLink?.cached_url : props.uploadSelfieLink?.cached_url;
        return navigateTo(redirectLink);
    }
    confirmationOpen.value = true;
    const confirmationPromise = confirmationRef.value.createPromise();
    const confirmed = await confirmationPromise;

    if (!confirmed) {
        confirmationOpen.value = false;
        toggleEditMode(type, true);
        return false;
    }

    confirmationOpen.value = false;
    await sleep(250);
    toggleEditMode(type);

    return false;
};

/*
    Selected confirmation modal
*/
const selectedConfirmationOpen = ref(false);
const selectedConfirmationRef = ref(null);

const onSelectedConfirmation = async (data, assignedTo, type) => {
    selectedConfirmationOpen.value = true;
    const selectedConfirmationPromise = selectedConfirmationRef.value.createPromise();
    const confirmed = await selectedConfirmationPromise;

    if (!confirmed) {
        selectedConfirmationOpen.value = false;
        return;
    }

    selectedConfirmationOpen.value = false;
    selectedPosition.value = {
        ...data,
        assignedTo,
        type,
    };

    const redirectLink = type === 'art' ? props.uploadArtLink?.cached_url : props.uploadSelfieLink?.cached_url;

    if (!redirectLink) return;
    setCursorVisibility(false);
    await navigateTo(redirectLink);
};

/*
    Reset open overlays when route changes
*/
watch(() => route.path, () => {
    confirmationOpen.value = false;
    selectedConfirmationOpen.value = false;
});

/*
    Mesh Data
*/
const meshData = ref({});
const setMeshData = (data) => {
    meshData.value[data.name] = data;
};

const { enterAnimation } = toRefs(props);

const alphaMap = shallowRef(null);
const emit = defineEmits(['on-ressources-loaded']);
const loadedStates = reactive({
    model: false,
    alphaMap: false,
    texture: false,
});

watch(() => loadedStates, (newValue) => {
    if (Object.values(newValue).every((state) => state)) {
        emit('on-ressources-loaded');
    }
}, { deep: true, immediate: true });

onMounted(() => {
    const textureLoader = new TextureLoader();
    alphaMap.value = textureLoader.load('/scene/ground/mask.jpg', () => {
        loadedStates.alphaMap = true;
    });
});

/*
    Open Overlay
*/
const onClick = async (event, data) => {
    if (!data.link) return;

    await navigateTo(data.link);
};

/*
  Pins positions
*/
const artPinsPositions = ref([]);
const groupPinsPositions = ref([]);

const flattenedPins = computed(() => [
    ...(pinsResult.value?.approved?.tableContributions ?? []),
    ...(pinsResult.value?.approved?.benchFourContributions ?? []),
    ...(pinsResult.value?.approved?.benchOneContributions ?? []),
    ...(pinsResult.value?.approved?.benchThreeContributions ?? []),
    ...(pinsResult.value?.approved?.benchTwoContributions ?? []),
]);

const setPinsPositions = (positions, type = 'artPins') => {
    if (!positions || !flattenedPins.value) return;

    if (type === 'artPins') {
        artPinsPositions.value = positions.map((pos) => {
            const pinData = flattenedPins.value?.find(
                (pin) => pin.group === pos.groupIndex && pin.sector === pos.sectorIndex,
            );

            return {
                positionX: pos.x,
                positionY: pos.y,
                zIndex: pos.zIndex,
                isHidden: pos.isHidden,
                borderRadius: pos.isTable
                    ? 'var(--b-radius--full)'
                    : 'var(--b-radius--pin)',
                data: {
                    ...pinData,
                },
            };
        });
    } else {
        groupPinsPositions.value = positions.map((pos) => ({
            positionX: pos.x,
            positionY: pos.y,
            zIndex: pos.zIndex,
            isHidden: pos.isHidden,
            position: pos.position,
            borderRadius: pos.isTable
                ? 'var(--b-radius--full)'
                : 'var(--b-radius--pin)',
            data: {
                amountOfPins: pos.amountOfPins,
                ...flattenedPins.value[pos.index],
            },
        }));
    }
};

const cameraRef = shallowRef(null);
const controlsRef = shallowRef(null);
const initialCameraPosition = {
    x: 26,
    y: 90,
    z: 26,
};

/*
  Page Transition Animation
*/
let rememberedCameraPosition = {
    x: 26,
    y: 26,
    z: 26,
};

const { gsap } = useGsap();
const hidePins = ref((route.path !== ('/' || '/home') && !route.path.includes('settings')) || enterAnimation.value === false);

const cameraPageTransition = async (mode) => {
    if (mode === 'in') {
        window.setTimeout(() => {
            hidePins.value = false;
        }, 1000);
        await sleep(500);
    }

    if (mode === 'out') {
        hidePins.value = true;
        rememberedCameraPosition = {
            x: cameraRef.value.position.x,
            y: cameraRef.value.position.y,
            z: cameraRef.value.position.z,
        };
    }

    gsap.to(cameraRef.value.position, {
        y: mode === 'in' ? rememberedCameraPosition.y : 90,
        duration: 0.8,
        ease: mode === 'in' ? 'power3.out' : 'power3.in',
        onUpdate: () => {
            cameraRef.value.updateProjectionMatrix();
        },
    });
};

watch(() => route.path, (newValue, oldValue) => {
    let mode = null;

    if (newValue === '/') {
        selectedPosition.value = null;
        mode = 'in';
    }

    if (oldValue === '/') {
        mode = 'out';
    }

    if (!mode) return;

    cameraPageTransition(mode);
});

watch(() => enterAnimation.value, (newValue) => {
    if (newValue && (route.path === ('/' || '/home') || route.path.includes('settings'))) {
        cameraPageTransition('in');
    }
});

/* Zoom in on click */
const onZoom = ($event, lookAt) => {
    const currentCameraPosition = cameraRef.value.position.clone();
    const targetPosition = new Vector3(lookAt.x, lookAt.y, lookAt.z);

    // Calculate the direction vector from the camera to the target
    const direction = new Vector3();
    direction.subVectors(currentCameraPosition, targetPosition).normalize();

    // Define the desired distance from the camera to the target
    const desiredDistance = 16; // Adjust this value as needed

    // Calculate the new camera position by
    // moving along the direction vector to the desired distance
    const newCameraPosition = targetPosition.clone().add(
        direction.multiplyScalar(desiredDistance),
    );

    // Intermediate position object for GSAP animation
    const intermediatePosition = {
        x: currentCameraPosition.x,
        y: currentCameraPosition.y,
        z: currentCameraPosition.z,
        targetX: controlsRef.value.value.target.x,
        targetY: controlsRef.value.value.target.y,
        targetZ: controlsRef.value.value.target.z,
    };

    // Animate the camera position and target position change
    gsap.to(intermediatePosition, {
        x: newCameraPosition.x,
        y: newCameraPosition.y,
        z: newCameraPosition.z,
        targetX: targetPosition.x,
        targetY: targetPosition.y,
        targetZ: targetPosition.z,
        duration: 0.6,
        ease: 'power3.out',
        onUpdate: () => {
            // Update the camera position
            cameraRef.value.position.set(
                intermediatePosition.x,
                intermediatePosition.y,
                intermediatePosition.z,
            );

            // Update the controls target position
            controlsRef.value.value.target.set(
                intermediatePosition.targetX,
                intermediatePosition.targetY,
                intermediatePosition.targetZ,
            );

            // Ensure the camera looks at the target position
            cameraRef.value.lookAt(targetPosition);
            cameraRef.value.updateProjectionMatrix();
            controlsRef.value.value.update();
        },
        onComplete: () => {
            // Ensure the camera ends up looking at the target position precisely
            cameraRef.value.position.set(
                newCameraPosition.x,
                newCameraPosition.y,
                newCameraPosition.z,
            );
            controlsRef.value.value.target.set(
                targetPosition.x,
                targetPosition.y,
                targetPosition.z,
            );
            cameraRef.value.lookAt(targetPosition);
            cameraRef.value.updateMatrixWorld(true); // Update the camera matrix world
            controlsRef.value.value.update(); // Update the controls
        },
    });
};
</script>

<style lang="scss" scoped>
.block-tres-purpletable {
    @include z-index('canvas');

    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    background: #E2C8E0;

    &.no-pointers {
        canvas {
            pointer-events: none;
        }
    }
}

.block-tres-purpletable__button {
    pointer-events: all;
}

.button-transition-move, /* apply transition to moving elements */
.button-transition-enter-active,
.button-transition-leave-active {
    transition: all 0.6s var(--ease-out--back);
}

.button-transition-enter-from,
.button-transition-leave-to {
    opacity: 0;
    transform: translateY(100%) translateX(-50%);
}

.button-transition-enter-active {
    transition-delay: 0.6s;
}

.block-tres-purpletable__buttons {
    @include z-index('header');
    @include fluid-simple('bottom', 50px, 50px);

    position: fixed;
    left: 0;
    display: flex;
    width: 100vw;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    pointer-events: none;
}
</style>
