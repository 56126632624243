// eslint-disable-next-line import/no-extraneous-dependencies
import { action } from '@storybook/addon-actions';

export default async (eventName, data) => {
    const runtimeConfig = useRuntimeConfig();

    if (runtimeConfig.public.IS_STORYBOOK) {
        action(eventName)(data);
    }
};
