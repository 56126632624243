export const popupVisible = ref(false);
export const popupMessage = ref('');
export const popupType = ref('success');

export const setPopup = (message, type = 'success') => {
    popupMessage.value = message;
    popupType.value = type;
    popupVisible.value = true;
};

export const hidePopup = () => {
    popupVisible.value = false;
};
