<template>
  <Teleport to="body">
    <div
      class="block-overlay-confirmation"
      :class="{ 'is-visible': isOpen }"
    >
      <div class="block-overlay-confirmation__grid">
        <slot />

        <div class="block-overlay-confirmation__buttons">
          <AtomButton
            v-if="rejectText"
            :text="rejectText"
            @click="reject"
          />

          <AtomButton
            v-if="confirmText"
            :text="confirmText"
            @click="confirm"
          />
        </div>

        <div
          class="block-overlay-confirmation__close"
          @click="reject"
          @keypress="reject"
        >
          <SvgoClose class="block-overlay-confirmation__close-icon" />
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script setup>
defineProps({
    title: {
        type: String,
        default: '',
    },

    text: {
        type: String,
        default: '',
    },

    confirmText: {
        type: String,
        default: '',
    },

    rejectText: {
        type: String,
        default: '',
    },

    isOpen: {
        type: Boolean,
        default: false,
    },
});

const resolvePromise = ref(null);
const isLocked = ref(false);
const createPromise = () => new Promise((resolve) => {
    resolvePromise.value = resolve;
    isLocked.value = true;
});

/*
      Handlers
  */
const confirm = () => {
    if (!resolvePromise.value) return;
    resolvePromise.value(true);
    isLocked.value = false;
};

const reject = () => {
    if (!resolvePromise.value) return;
    resolvePromise.value(false);
    isLocked.value = false;
};

/*
      Expose
  */
defineExpose({
    createPromise,
});

</script>

<style lang="scss" scoped>
.block-overlay-confirmation {
    @include z-index('confirmOverlay');

    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    width: 100vw;
    height: 100dvh;
    align-items: center;
    padding: var(--page-padding);
    backdrop-filter: blur(5px);
    background: rgba(255, 255, 255, 0.15);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease-in-out;

    &.is-visible {
        opacity: 1;
        pointer-events: all;
    }
}

.block-overlay-confirmation__grid {
    @include wrapper-layout;
    @include fluid-simple('border-radius', 15px, 30px);
    @include fluid-simple('padding-top', 25px, 50px);
    @include fluid-simple('padding-bottom', 55px, 50px);
    @include fluid-simple('padding-left', 20px, 50px);
    @include fluid-simple('padding-right', 20px, 50px);

    display: flex;
    width: 100%;
    max-width: 512px;
    flex-direction: column;
    align-content: center;
    border: 2px solid var(--c-white);
    background: var(--c-white);
    row-gap: 15px;
}

.block-overlay-confirmation__buttons {
    z-index: 1;
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;
    column-gap: 10px;
    row-gap: 10px;
}

.block-overlay-confirmation__title,
.block-overlay-confirmation__text {

    @include desktop {
        grid-column-end: span 8;
    }
}

.block-overlay-confirmation__close {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    border: 2px solid var(--c-white);
    border-radius: var(--b-radius--full);
    margin: auto;
    background-color: var(--c-primary--light);
    cursor: pointer;
    transform: translateY(50%) translateY(-7px);
    transition: transform 0.3s ease;

    @include tablet-portrait {
        top: 0;
        left: unset;
        transform: translateX(50%) translateX(-7px);

        @include only-hover {
            &:hover {
                transform: translateX(50%) translateX(-5px) scale(1.1) rotate(90deg);
            }
        }
    }
}

.block-overlay-confirmation__close-icon {
    width: 10px;
    height: 10px;
}
</style>
