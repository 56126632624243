/*
    Usage:
    You can everywhere just use the variables exported below.
    They are reactive and will update on window resize.

    @example
    <div v-if="isTablet">I am only visible on tablet</div>

    If you use them inside script setup you need to get the value with .value
    if(isTablet.value) {
        console.log('I am only visible on tablet');
    }
*/

const breakpoints = useBreakpoints({
    mobile: 480,
    tabletPortrait: 750,
    tablet: 1000,
    desktop: 1200,
});

export const isMobile = breakpoints.smaller('mobile');
export const isTabletPortrait = breakpoints.between('mobile', 'tabletPortrait');
export const isTablet = breakpoints.between('tabletPortrait', 'tablet');
export const isDesktop = breakpoints.greaterOrEqual('tablet');

export const isGreaterThanMobile = breakpoints.greater('mobile');
export const isGreaterThanTabletPortrait = breakpoints.greater('tabletPortrait');
export const isGreaterThanTablet = breakpoints.greater('tablet');
export const isGreaterThanDesktop = breakpoints.greater('desktop');
