<template>
  <div
    class="ion-icon"
    :class="{ 'has-icon-color': iconColor }"
  >
    <component
      :is="`Svgo${toUpperCamelCase(iconName)}`"
      :key="uniqueKey"
      :class="{
        'has-width': iconWidth,
        'has-height': iconHeight,
      }"
      class="ion-icon__svg"
    />
  </div>
</template>

<script setup>
const props = defineProps({
    iconName: {
        type: String,
        required: true,
    },

    iconColor: {
        type: String,
        default: null,
    },

    iconWidth: {
        type: String,
        default: null,
    },

    iconHeight: {
        type: String,
        default: null,
    },
});

const {
    iconColor,
    iconWidth,
} = toRefs(props);

/*
    Rerender the asnyc child component when the icon name changes.
  */
const uniqueKey = ref(getUid());

watch(() => props.name, () => {
    uniqueKey.value = getUid();
});
</script>

<style lang="scss" scoped>
.ion-icon {
    display: flex;

    &.has-icon-color {
        transition: fill 0.3s ease, stroke 0.3s ease, color 0.3s ease;

        ::v-deep(path) {
            fill: v-bind(iconColor);
        }
    }

    .has-error & {
        transition: fill 0.3s ease, stroke 0.3s ease, color 0.3s ease;

        ::v-deep(path) {
            fill: var(--c-error) !important;
        }
    }
}

.ion-icon__svg {
    width: 100%;
    height: auto;

    &.has-height {
        width: auto;
        height: v-bind(iconHeight);
    }

    &.has-width {
        width: v-bind(iconWidth);
        height: auto;
    }
}
</style>
