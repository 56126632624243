<!-- eslint-disable vue/attribute-hyphenation -->
<template>
  <TresGroup>
    <TresMesh
      ref="fakeTableBoxRef"
      :position="[0, 1.7, 0]"
    >
      <TresBoxGeometry
        :args="[36.5, 3.5, 5.25]"
      />
      <TresMeshBasicMaterial
        color="white"
        :transparent="true"
        :opacity="0"
      />
    </TresMesh>

    <!-- v-for="mesh of model?.children" -->
    <TresGroup
      v-for="mesh of model?.children.filter((child) => child.name !== 'Cylinder002')"
      :key="mesh.name"
    >
      <primitive
        :object="mesh"
      />

      <TresGroup v-if="mesh.pinCubes?.groups">
        <TresGroup
          v-for="(group, groupIndex) of mesh.pinCubes.groups"
          :key="`pin-group-${groupIndex}`"
        >
          <TresMesh
            v-if="group.groupPin"
            ref="groupPinRefs"
            :position="[
              group.groupPin.position.x,
              group.groupPin.position.y,
              group.groupPin.position.z,
            ]"
            :userData="{
              isGroupPin: true,
              position: group.groupPin.position,
              groupIndex,
              amountOfPins: group.pins?.length || 0,
              isTable: mesh.name.includes('tisch'),
              meshName: mesh.name,
            }"
          >
            <TresBoxGeometry
              :args="[
                group.groupPin.size.x,
                group.groupPin.size.y,
                group.groupPin.size.z,
              ]"
            />

            <TresMeshBasicMaterial
              :color="getRandomColorHex()"
              transparent
              :opacity="0"
            />
          </TresMesh>

          <TresMesh
            v-for="pin of group.pins"
            :key="pin._uid"
            ref="artPinRefs"
            :userData="{
              isArtPin: true,
              groupIndex: pin.groupIndex,
              sectorIndex: pin.sectorIndex,
              _uid: pin._uid,
              meshName: mesh.name,
              isTable: mesh.name.includes('tisch'),
            }"
            :position="[
              pin.position.x,
              pin.position.y,
              pin.position.z,
            ]"
          >
            <TresBoxGeometry
              :args="[
                pin.size.x,
                pin.size.y,
                pin.size.z,
              ]"
            />

            <TresMeshBasicMaterial
              :color=" `#FFFFFF`"
              transparent
              :opacity="0"
            />
          </TresMesh>
        </TresGroup>
      </TresGroup>
    </TresGroup>
  </TresGroup>
</template>

<script setup>
/* eslint-disable no-param-reassign */
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass';
import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader';

import {
    TextureLoader,
    MeshBasicMaterial,
    SRGBColorSpace,
    Vector3,
    Box3,
    Raycaster,
} from 'three';

const props = defineProps({
    fog: {
        type: Boolean,
        default: true,
    },

    tableContributions: {
        type: Array,
        default: () => [],
    },

    benchOneContributions: {
        type: Array,
        default: () => [],
    },

    benchTwoContributions: {
        type: Array,
        default: () => [],
    },

    benchThreeContributions: {
        type: Array,
        default: () => [],
    },

    benchFourContributions: {
        type: Array,
        default: () => [],
    },
});

/*
        Deps
  */
const {
    camera, sizes, scene, renderer,
} = useTresContext();
const { onBeforeRender } = useLoop();

const textureLoader = new TextureLoader();

const dracoLoader = new DRACOLoader();
dracoLoader.setDecoderPath('draco/');

const gltfLoader = new GLTFLoader();
gltfLoader.setDRACOLoader(dracoLoader);

const initResult = shallowRef([]);

const composer = new EffectComposer(renderer.value);
const renderPass = new RenderPass(scene.value, camera.value);
composer.addPass(renderPass);

const fxaaPass = new ShaderPass(FXAAShader);
fxaaPass.uniforms.resolution.value.set(1 / sizes.width.value, 1 / sizes.height.value);
composer.addPass(fxaaPass);

/*
    2D Projection for pins
*/
const artPinRefs = shallowRef([]);
const groupPinRefs = shallowRef([]);
const artPinPositions2d = shallowRef([]);
const groupPinPositions2d = shallowRef([]);
const fakeTableBoxRef = shallowRef(null);
const emit = defineEmits(
    [
        'on-update-art-pins',
        'on-update-group-pins',
        'on-loaded-model',
        'on-loaded-texture',
        'on-set-mesh-data',
    ],
);

const calculatePositions = (pins, type) => {
    if (!isArrayNotEmpty(pins.value)) return [];
    const width = sizes.width.value;
    const height = sizes.height.value;
    scene.value?.updateMatrixWorld();

    const artPinPositionsWithDepth = [];
    const raycaster = new Raycaster();

    const minDistance = 0; // Set your desired minimum distance for visibility
    const maxDistance = 30; // Set your desired maximum distance for visibility

    const pointOfInterest = new Vector3(0, 0, 0); // Replace with your desired point if needed
    const cameraPosition = camera.value.position;
    const cameraDistance = cameraPosition.distanceTo(pointOfInterest);

    pins.value.forEach((mesh, index) => {
        const vector = new Vector3();
        vector.setFromMatrixPosition(mesh.matrixWorld);

        // Determine if the pin is part of a group with multiple pins
        const groupHasMultiplePins = initResult.value.some(
            (group) => group.pins.some(
                (pin) => pin._uid === mesh.userData._uid,
            ) && group.pins.length > 1,
        );

        // Set up the raycaster
        raycaster.set(camera.value.position, vector.sub(camera.value.position).normalize());

        // Check for intersections with the fakeTableBoxRef
        const intersects = raycaster.intersectObject(fakeTableBoxRef.value, true);

        // Determine if the pin is hidden by the table
        const isHiddenByTable = props.hidePins ? true : intersects.length > 0
          && intersects[0].distance < camera.value.position.distanceTo(mesh.position);

        // Check if the pin is within the distance range if it belongs to a group with multiple pins
        const isWithinDistanceRange = cameraDistance >= minDistance
            && cameraDistance <= maxDistance;

        const isHidden = isHiddenByTable
            || (type === 'groupPins' && cameraDistance <= maxDistance)
            || (groupHasMultiplePins && !isWithinDistanceRange);

        // Project the vector to normalized device coordinates (NDC)
        vector.setFromMatrixPosition(mesh.matrixWorld);
        vector.project(camera.value);

        // Convert NDC to screen coordinates
        const normalizedX = (vector.x + 1) / 2;
        const normalizedY = (1 - vector.y) / 2;

        // Calculate screen coordinates directly
        const screenX = normalizedX * width;
        const screenY = normalizedY * height;

        const x = screenX;
        const y = screenY;

        // Calculate depth (z) value
        const depth = vector.z;

        artPinPositionsWithDepth.push({
            index,
            x,
            y,
            depth,
            isHidden: isHiddenByTable || isHidden,
            ...mesh.userData,
        });
    });

    // Sort pins based on depth (closer pins should have a higher z-index)
    artPinPositionsWithDepth.sort((a, b) => b.depth - a.depth);

    // Assign z-index based on sorted order
    artPinPositionsWithDepth.forEach((pin, sortedIndex) => {
        if (type === 'artPins') {
            artPinPositions2d.value[pin.index] = {
                index: pin.index,
                x: pin.x,
                y: pin.y,
                zIndex: sortedIndex,
                groupIndex: pin.groupIndex,
                sectorIndex: pin.sectorIndex,
                isHidden: pin.isHidden,
                meshName: pin.meshName,
                isTable: pin.isTable,
            };
        }

        if (type === 'groupPins') {
            groupPinPositions2d.value[pin.index] = {
                index: pin.index,
                x: pin.x,
                y: pin.y,
                zIndex: sortedIndex,
                groupIndex: pin.groupIndex,
                sectorIndex: pin.sectorIndex,
                isHidden: pin.isHidden,
                meshName: pin.meshName,
                isTable: pin.isTable,
                amountOfPins: pin.amountOfPins,
                position: pin.position,
            };
        }
    });

    if (type === 'artPins') {
        return artPinPositions2d.value;
    }

    if (type === 'groupPins') {
        return groupPinPositions2d.value;
    }

    return [];
};

onBeforeRender(() => {
    if (!artPinRefs.value || !window || !fakeTableBoxRef.value || !camera.value) return;

    renderer.value.setPixelRatio(window.devicePixelRatio);
    composer.render();
    const artPinPositions = calculatePositions(artPinRefs, 'artPins');
    const groupPinPositions = calculatePositions(groupPinRefs, 'groupPins');

    emit('on-update-art-pins', artPinPositions);
    emit('on-update-group-pins', groupPinPositions);
}, 0);
/*
        Textures
*/
const bakedTexture = textureLoader.load('/scene/table/baked-studio.jpg', () => {
    emit('on-loaded-texture');
});
bakedTexture.flipY = false;
bakedTexture.colorSpace = SRGBColorSpace;

/*
        Materials
  */
const bakedMaterial = new MeshBasicMaterial(
    {
        map: bakedTexture,
        fog: props.fog,
    },
);

/*
        Model
*/
const groupsSettings = {
    table: {
        x: 10,
        z: 3,
    },
    bench: {
        x: 5,
        z: 1,
    },
};
const sectorsSettings = {
    table: {
        x: 3,
        z: 3,
    },

    bench: {
        x: 3,
        z: 3,
    },
};

const model = shallowRef(null);

const filterPins = (groupIndex, group, checkObj) => {
    const filtered = group.filter(
        (pin, innerIndex) => {
            const found = checkObj.some(
                (contribution) => contribution.group === groupIndex
                  && contribution.sector === innerIndex,
            );

            return found;
        },
    );

    return filtered;
};

// gltfLoader.load('/scene/table/Table-studio.glb', (gltf) => {
gltfLoader.load('/scene/table/Table-studio.glb', (gltf) => {
    gltf.scene.traverse((child) => {
        child.material = bakedMaterial;
        child.pinGroups = {};

        if (
            child.isMesh
                && (child.name.includes('bank') || child.name.includes('tisch'))
        ) {
            // Ensure the matrix is up to date
            child.updateMatrixWorld(true);

            const isTable = child.name.includes('tisch');
            const groups = isTable ? groupsSettings.table : groupsSettings.bench;
            const sectors = isTable ? sectorsSettings.table : sectorsSettings.bench;

            const { y } = child.position;
            const boundingBox = new Box3().setFromObject(child);
            const { min, max } = boundingBox;
            const xSize = max.x - min.x;
            const zSize = max.z - min.z;

            const groupSizes = {
                x: xSize / groups.x,
                z: zSize / groups.z,
            };

            const sectorSizes = {
                x: xSize / (sectors.x * groups.x),
                z: zSize / (sectors.z * groups.z),
            };

            emit('on-set-mesh-data', {
                name: child.name,
                groupSizes,
                sectorSizes,
                boundingBox,
                min,
                max,
                groups,
            });

            /*
                PinCubes
            */
            child.pinCubes = {
                groups: Array.from({ length: groups.x * groups.z }, (_, groupIndex) => {
                    const length = sectors.x * sectors.z;
                    const groupStart = {
                        x: min.x + (groupIndex % groups.x) * sectorSizes.x * sectors.x + 0.555,
                        z: min.z + Math.floor(
                            groupIndex / groups.x,
                        ) * sectorSizes.z * sectors.z + 0.3,
                    };

                    const result = Array.from({ length }, (__, innerIndex) => {
                        const position = {
                            x: groupStart.x + (innerIndex % sectors.x) * sectorSizes.x,
                            z: groupStart.z + Math.floor(innerIndex / sectors.x) * sectorSizes.z,
                            y: y + 0.25,
                        };

                        const size = {
                            x: sectorSizes.x,
                            z: sectorSizes.z,
                            y: 0.1,
                        };

                        const _uid = `pin-${getUid()}-${groupIndex}-${innerIndex}`;
                        return {
                            position,
                            zIndex: 0,
                            groupIndex,
                            sectorIndex: innerIndex,
                            type: isTable ? 'circle' : 'square',
                            size,
                            _uid,
                        };
                    });

                    // return result;
                    let checkGroup = null;
                    let checkGroupName = null;

                    if (isTable) {
                        checkGroup = props.tableContributions;
                        checkGroupName = 'tableContributions';
                    } else {
                        switch (child.name) {
                        case 'bank':
                            checkGroup = props.benchFourContributions;
                            checkGroupName = 'benchFourContributions';
                            break;
                        case 'bank001':
                            checkGroup = props.benchOneContributions;
                            checkGroupName = 'benchOneContributions';
                            break;
                        case 'bank002':
                            checkGroup = props.benchTwoContributions;
                            checkGroupName = 'benchTwoContributions';
                            break;
                        case 'bank003':
                            checkGroup = props.benchThreeContributions;
                            checkGroupName = 'benchThreeContributions';
                            break;
                        default:
                            checkGroup = null;
                            checkGroupName = null;
                        }
                    }

                    if (!checkGroup || !checkGroupName) return [];

                    const filteredPins = filterPins(
                        groupIndex,
                        result,
                        checkGroup,
                    );

                    /*
                        Group Cube
                    */
                    const groupCube = {
                        position: {
                            x: groupStart.x + groupSizes.x / 2 - 0.555,
                            z: groupStart.z + groupSizes.z / 2 - 0.3,
                            y: y + 0.35,
                        },
                        size: {
                            x: groupSizes.x,
                            z: groupSizes.z,
                            y: 0.1,
                        },
                    };

                    child.groupCube = groupCube;

                    const hasGroupPin = filteredPins.length > 1;

                    initResult.value = [
                        ...initResult.value,
                        {
                            pins: filteredPins.map((pin) => ({
                                ...pin,
                                hasGroupPin,
                            })),
                            // Only add groupPin if there are more than 1 pin in this group
                            groupPin: hasGroupPin ? groupCube : null,
                        },
                    ];

                    return {
                        pins: filteredPins.map((pin) => ({
                            ...pin,
                            hasGroupPin,
                        })),
                        // Only add groupPin if there are more than 1 pin in this group
                        groupPin: hasGroupPin ? groupCube : null,
                    };
                }).filter((group) => group.pins.length > 0),
            };
        }
    });

    model.value = gltf.scene;
    emit('on-loaded-model');
});

defineExpose({
    artPinRefs,
});
</script>
